import React from "react";

import Layout from "../components/layout";
import HeroBanner from "../components/homepage/hero-banner";

const ContactUsPage = () => (
    <Layout>
        <HeroBanner />
    </Layout>
);

export default ContactUsPage;
